<template>
	<div class="spinner-container">
		<b-spinner
			style="width: 3rem; height: 3rem; margin: 0 auto; display: block"
			variant="primary"
		/>
	</div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import { HTTP } from '@/libs/axios';

export default {
	components: {
		BSpinner,
	},
	computed: {
		...mapState('userManagement', ['vendorToken']),
	},
	created() {
		const { hash } = this.$route.query;
		this.impersonate(hash);
	},
	methods: {
		...mapActions('userManagement', ['vendorSessionAuth', 'getUserModules']),
		...mapActions('accountSettings', ['getUserInfo']),
		impersonate(hash) {
			localStorage.clear();
			this.vendorSessionAuth(hash)
				.then(() => {
					const parsedToken = this.parseJwt(this.vendorToken);
					localStorage.setItem('userInfo', JSON.stringify(parsedToken));
					localStorage.setItem('token', this.vendorToken);
					HTTP.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
						'token'
					)}`;

					this.getUserModules();
					this.getUserInfo();
				})
				.then(() => {
					this.$router.push('/');
				});
		},
		parseJwt(token) {
			const base64Url = token.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split('')
					.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
					.join('')
			);

			return JSON.parse(jsonPayload);
		},
	},
};
</script>

<style lang="scss" scoped>
.spinner-container {
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
